import { Heading, HStack, IconButton } from "@efishery/onefish";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

import { IMobileHeaderProps } from "./type";

export function MobileHeader({
  transparent,
  title,
  onPreviousClick,
  onNextClick,
  customRender,
  chakra,
}: IMobileHeaderProps) {
  const setJustify = () => {
    if (!onNextClick && !onPreviousClick) {
      return "center";
    }
    if (!onPreviousClick) {
      return "flex-end";
    }
    if (!onNextClick) {
      return "flex-start";
    }
    return "space-between";
  };

  if (customRender) {
    return (
      <HStack
        height="55px"
        width="full"
        position="sticky"
        top={0}
        zIndex="999"
        padding="10px"
        marginBottom={transparent ? "-55px" : "0"}
        {...chakra?.container}
      >
        {customRender}
      </HStack>
    );
  }

  return (
    <HStack
      height="55px"
      width="full"
      justify={setJustify()}
      position="sticky"
      top={0}
      zIndex="999"
      padding="10px"
      marginBottom={transparent ? "-55px" : "0"}
      {...chakra?.container}
    >
      {onPreviousClick && (
        <IconButton
          variant="ghost"
          border="none"
          aria-label="Previous Button"
          icon={<BsArrowLeft size="20px" />}
          boxSize="30px"
          color={chakra?.colorScheme ?? "brand.500"}
          onClick={onPreviousClick}
          data-testid="btn_header-previous"
        />
      )}
      {title && (
        <Heading
          color={chakra?.colorScheme ?? "brand.500"}
          size="lg"
          fontWeight="500"
          justifySelf="center"
          data-testid="txt_header-title"
        >
          {title}
        </Heading>
      )}
      {onNextClick && (
        <IconButton
          variant="ghost"
          border="none"
          aria-label="Next Button"
          icon={<BsArrowRight size="20px" />}
          boxSize="30px"
          color={chakra?.colorScheme ?? "brand.500"}
          onClick={onNextClick}
          data-testid="btn_header-next"
        />
      )}
    </HStack>
  );
}
