import { createContext, useState } from "react";

import { ToastContainer } from "./ToastContainer";
import { ToastContextProps, ToastOptions, ToastProviderProps } from "./type";
import { useToastContext } from "./useToastContext";

export const ToastContext = createContext<ToastContextProps>({
  toast: () => {},
});

export const ToastProvider = ({ children, ...props }: ToastProviderProps) => {
  const { toast, toastConfigs, removeAllToast } = useToastContext();
  const [toastOptions, setToastOptions] = useState<ToastOptions>({});

  const value = {
    toast,
    setToastOptions,
  };
  return (
    <ToastContext.Provider value={value} {...props}>
      <ToastContainer
        toastConfigs={toastConfigs}
        removeAllToast={removeAllToast}
        options={toastOptions}
      />
      {children}
    </ToastContext.Provider>
  );
};
