export function encodeString(string: string, separator: string = "-") {
  const strings = string
    .toLowerCase()
    .split(" ")
    .filter((string) => string !== "");
  return strings.join(separator);
}

export function toCamelCase(str: string) {
  return str.replace(/\s(\w)/g, (_, c) => c.toUpperCase());
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}
