import { Center, VStack } from "@efishery/onefish";
import React from "react";
import { ToastProvider } from "src/context";

import { Container } from "..";
import { MobileFooter } from "../Footer";
import { MobileHeader } from "../Header/mobile";
import { IMobileLayoutProps } from "./type";

export function MobileLayout({
  children,
  chakra,
  headerProps,
  footerProps,
  useOuterContainer,
  useFragmentWrapper,
}: IMobileLayoutProps) {
  const Wrapper = useFragmentWrapper ? React.Fragment : Container;
  if (useOuterContainer) {
    return (
      <Wrapper>
        <Center
          id="layout-mobile-container"
          position="relative"
          {...chakra?.outer}
        >
          <VStack
            id="mobile-container"
            position="relative"
            minHeight="100vh"
            maxW="480px"
            w="100%"
            spacing="0"
            boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
            bg="rgb(248, 248, 248)"
            {...chakra?.container}
          >
            <ToastProvider>
              {headerProps && <MobileHeader {...headerProps} />}
              {children}
              {footerProps && <MobileFooter {...footerProps} />}
            </ToastProvider>
          </VStack>
        </Center>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <VStack
        id="mobile-container"
        position="relative"
        minHeight="100vh"
        maxW="480px"
        w="100%"
        spacing="0"
        boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
        bg="rgb(248, 248, 248)"
        {...chakra?.container}
      >
        <ToastProvider>
          {headerProps && <MobileHeader {...headerProps} />}
          {children}
          {footerProps && <MobileFooter {...footerProps} />}
        </ToastProvider>
      </VStack>
    </Wrapper>
  );
}
