import { Button, VStack } from "@efishery/onefish";
import { encodeString } from "src/utils/string";

import { IMobileFooterProps } from "./type";

export function MobileFooter({
  customRender,
  chakra,
  buttonProps,
}: IMobileFooterProps) {
  if (customRender) {
    return customRender;
  }

  return (
    <VStack
      position="absolute"
      bottom="0"
      width="full"
      minHeight="55px"
      bg="brand.100"
      {...chakra?.container}
    >
      {buttonProps?.map((props, i) => (
        <Button
          key={i}
          data-testid={`btn_footer-button-${encodeString(props.title ?? "")}`}
          {...props}
        >
          {props.title}
        </Button>
      ))}
    </VStack>
  );
}
