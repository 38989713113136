import { useState } from "react";

import { ToastConfig, ToastContextHooksReturnValue } from "./type";

export function useToastContext(): ToastContextHooksReturnValue {
  const [toastConfigs, setToastConfigs] = useState<ToastConfig[]>([]);

  const toast = (toastConfig: ToastConfig) => {
    setToastConfigs((prev) => [
      ...prev,
      { ...toastConfig, id: `toast-${prev.length + 1}` },
    ]);
  };

  const removeAllToast = () => {
    setToastConfigs([]);
  };
  return {
    toast,
    toastConfigs,
    removeAllToast,
  };
}
