import { CrudFilters } from "src/libs/dataProvider/types";

import { mapOperator } from "./mapOperator";

export const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: { [key: string]: any } } = {};

  if (filters) {
    filters.map((filter) => {
      if (filter.operator === "or" || filter.operator === "and") {
        throw new Error(
          `[micro-belut-service-rest]: \`operator: ${filter.operator}\` is not supported yet.`
        );
      }

      if ("field" in filter) {
        const { field, operator, value } = filter;

        const mappedOperator = mapOperator(operator);
        queryFilters[field] = {
          [mappedOperator]: value,
        };
      }
    });
  }

  return queryFilters;
};
