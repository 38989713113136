export const ENV = {
  APP_ID: import.meta.env.VITE_APP_ID ?? "growth-remote",
  APP_HOST: import.meta.env.VITE_APP_HOST ?? "http://localhost:3001",
  APP_VERSION_TAG:
    import.meta.env.VITE_APP_VERSION_TAG ?? "growth-remote-v0.0.0-dev",
  APP_ENV: import.meta.env.VITE_APP_ENV ?? "",
  APP_CLIENT_ID: import.meta.env.VITE_APP_CLIENT_ID ?? "",
  APP_EFISHERYCOM_WEB: import.meta.env.VITE_APP_EFISHERYCOM_WEB ?? "",
  APP_SENTRY_DSN: import.meta.env.VITE_APP_SENTRY_DSN ?? "",
};
