import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { ENV } from "src/configs/env";

const addHeaderInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      if (config.headers !== undefined) {
        const { headers } = config;
        config.headers.set(
          "Authorization",
          `Bearer ${headers["Authorization"] ?? ""}`
        );
        config.headers.set("X-Client-Id", headers["X-Client-Id"] ?? ENV.APP_ID);
        config.headers.set(
          "X-Client-Version",
          headers["X-Client-Version"] ?? ENV.APP_VERSION_TAG
        );
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const defaultResponseInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      const customError = {
        ...err,
        message:
          err.response?.data?.metadata?.error?.message ||
          err.response?.data?.message,
        statusCode:
          err.response?.data?.metadata?.error?.code || err?.response?.status,
      };

      return Promise.reject(customError);
    }
  );
};

const createAxios: () => AxiosInstance = () => {
  const axiosInstance = axios.create();

  addHeaderInterceptor(axiosInstance);
  defaultResponseInterceptors(axiosInstance);

  return axiosInstance;
};

export const axiosInstance = createAxios();
