"use client";
import "./style.css";

import { OneFishProvider } from "@efishery/onefish";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { IContainerProps } from "./type";

dayjs.extend(utc);
dayjs.extend(timezone);

export function Container({ children }: IContainerProps) {
  return <OneFishProvider>{children}</OneFishProvider>;
}
