import { AxiosInstance } from "axios";
import queryString from "query-string";
import { axiosInstance, generateFilter, generateSort } from "src/utils";

import { DataProvider, MethodTypes, MethodTypesWithBody } from "./types";

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): DataProvider => {
  return {
    getList: async ({ resource, pagination, filters, sorters, meta }) => {
      try {
        const url = `${apiUrl}/${resource}`;

        const {
          current = 1,
          pageSize = 10,
          mode = "server",
        } = pagination ?? {};

        const { headers: headersFromMeta, method, params = {} } = meta ?? {};
        const requestMethod = (method as MethodTypes) ?? "get";

        const queryFilters = generateFilter(filters);

        const query: {
          _start?: number;
          _end?: number;
          _sort?: string;
          _order?: string;
          type?: "offset" | "cursor";
          query?: { [key: string]: any };
        } = {};

        if (mode === "server") {
          query.type = "offset";
          query.query = {
            ...query.query,
            limit: pageSize,
            offset: (current - 1) * pageSize,
            where: queryFilters,
          };
          // query._start = (current - 1) * pageSize;
          // query._end = current * pageSize;
        } else if (mode === "off") {
          return {
            data: [],
            total: 0,
          };
        }

        const generatedSort = generateSort(sorters);
        if (generatedSort) {
          query.query = {
            ...query.query,
            order_by: generatedSort,
          };
        }

        const { data } = await httpClient[requestMethod](
          `${url}?${queryString.stringify({
            type: query.type,
            query: JSON.stringify(query.query),
            ...params,
          })}`,
          {
            headers: headersFromMeta,
          }
        );

        const { data: listData } = data;
        return {
          data: listData || [],
          total: listData?.length || 0,
        };
      } catch (error: any) {
        return {
          data: {
            success: false,
            data: [],
            message: error.message,
            error,
          },
          total: 0,
        };
      }
    },

    getOne: async ({ resource, id, meta }) => {
      try {
        const url = `${apiUrl}/${resource}/${id}`;

        const { headers, method, params = {} } = meta ?? {};
        const requestMethod = (method as MethodTypes) ?? "get";

        const { data } = await httpClient[requestMethod](
          `${url}?${queryString.stringify({ ...params })}`,
          { headers }
        );

        return {
          data,
        };
      } catch (error: any) {
        return {
          data: {
            success: false,
            data: null,
            message: error.message,
            error,
          },
        };
      }
    },

    create: async ({ resource, variables, meta }) => {
      try {
        const url = `${apiUrl}/${resource}`;

        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypesWithBody) ?? "post";

        const { data } = await httpClient[requestMethod](url, variables, {
          headers,
        });

        return {
          data,
        };
      } catch (error: any) {
        return {
          data: {
            success: false,
            data: null,
            message: error.message,
            error,
          },
        };
      }
    },

    update: async ({ resource, variables, id, meta }) => {
      try {
        const url = `${apiUrl}/${resource}/${id}`;

        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypesWithBody) ?? "put";

        const { data } = await httpClient[requestMethod](url, variables, {
          headers,
        });

        return {
          data,
        };
      } catch (error: any) {
        return {
          data: {
            success: false,
            data: null,
            message: error.message,
            error,
          },
        };
      }
    },

    getApiUrl: () => {
      return apiUrl;
    },
  };
};
