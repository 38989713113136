import { CrudSorting } from "src/libs/dataProvider/types";

export const generateSort = (sorters?: CrudSorting) => {
  if (sorters && sorters.length > 0) {
    const sorterResult = sorters.map((item) => ({
      field_name: item.field,
      sort: item.order.toUpperCase(),
    }));

    return sorterResult;
  }

  return;
};
