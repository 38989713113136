import { CrudOperators } from "src/libs/dataProvider/types";

export const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "gt":
      return ">";
    case "lt":
      return "<";
    case "gte":
      return `>=`;
    case "lte":
      return `<=`;
    case "ne":
    case "in":
    case "nin":
    case "null":
    case "nnull":
      return `_${operator}`;
    case "contains":
      return "isubstring";
    case "ncontains":
      return "notilike";
    case "startswith":
      return `istartswith`;
    case "nstartswith":
      return `notistartswith`;
    case "endswith":
      return `iendswith`;
    case "nendswith":
      return `notiendswith`;
    case "eq":
      return "eq";
    default:
      return "unsupported";
  }
};
