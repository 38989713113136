/* eslint-disable  */
import { jwtDecode } from "jwt-decode";
import TagManager from "react-gtm-module";
import { ENV } from "src/configs/env";

export type TrackerProps = {
  dataLayer: {
    event: string;
    eventCategory: string;
    eventLabel?: { [key: string]: string } | string;
    pageTitle: string;
  };
};

const UTM_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_id",
  "utm_term",
  "utm_content",
];

export const dataLayer = async (currObj: TrackerProps, token?: string) => {
  try {
    const dataUser = token && jwtDecode<{ lead_id: string }>(token);
    const currentVersion = window?.appVersion || "";
    const customDeviceId = window?.customDeviceId || "";
    const leadId = (dataUser && dataUser?.lead_id) || "";
    const utmParams: { [key: string]: any } = {};

    for (const key of UTM_PARAMS) {
      if (sessionStorage.getItem(key))
        utmParams[key] = sessionStorage.getItem(key);
    }

    const tempObj = {
      ...currObj,
      dataLayer: {
        ...currObj.dataLayer,
        userId: leadId,
        customDeviceId,
        webAppVersion: ENV.APP_VERSION_TAG || "",
        appVersion: currentVersion || "",
        utmParams,
      },
    };

    // GTM
    TagManager.dataLayer(tempObj);
    if (ENV.APP_ENV === "staging") {
      console.debug("🏷 [GTM]", tempObj.dataLayer.event, tempObj.dataLayer);
    }
  } catch (err) {
    console.error(err);
  }
};

const GTMManager = {
  dataLayer,
};

export default GTMManager;
