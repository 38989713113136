import {
  Box,
  HStack,
  Spinner,
  StackProps,
  Text,
  VStack,
} from "@efishery/onefish";
import { useEffect, useState } from "react";
import {
  BsCheckCircleFill,
  BsExclamationCircleFill,
  BsInfoCircleFill,
  BsQuestionCircleFill,
  BsXCircleFill,
} from "react-icons/bs";

import {
  RenderStyleByStatusReturnValue,
  ToastConfigStatus,
  ToastContainerProps,
  ToastItemProps,
} from "./type";

export function ToastContainer({
  toastConfigs,
  removeAllToast,
  options,
}: ToastContainerProps) {
  const [removeIds, setRemoveIds] = useState<string[]>([]);
  const globalPosition = options?.position ?? "top";
  const removeToast = (id: string) => {
    setRemoveIds((prev) => {
      const toastIndex = removeIds.findIndex((v) => v === id);
      if (toastIndex === -1) {
        return [...prev, id];
      } else {
        return prev;
      }
    });
  };

  useEffect(() => {
    if (removeIds.length > 0 && removeIds.length === toastConfigs.length) {
      removeAllToast();
      setRemoveIds([]);
    }
  }, [removeIds]);
  return (
    <>
      <VStack
        alignItems="center"
        position="absolute"
        paddingX="16px"
        height="full"
        width="full"
        zIndex={toastConfigs.length > 0 ? undefined : -1}
      >
        <VStack
          alignItems="center"
          maxW="480px"
          position="fixed"
          top={globalPosition === "top" ? "75px" : undefined}
          bottom={globalPosition === "bottom" ? "50px" : undefined}
          zIndex="9999"
          width="full"
          spacing="16px"
          {...options?.layoutStyle}
        >
          {toastConfigs.map((config, i) => (
            <ToastItem
              key={`toast-${i}`}
              toastConfig={config}
              removeToast={removeToast}
            />
          ))}
        </VStack>
      </VStack>
    </>
  );
}

export function ToastItem({ toastConfig, removeToast }: ToastItemProps) {
  const {
    id,
    title,
    status,
    description,
    duration = 2000,
    containerStyle: customContainerStyle,
  } = toastConfig;
  const defaultContainerStyle: StackProps = {
    justifyContent: "center",
    width: "full",
    maxWidth: "440px",
    minHeight: "46px",
    borderRadius: "8px",
    spacing: "4px",
    paddingX: "16px",
    paddingY: "8px",
    transition: "all ease 0.5s",
    opacity: 0,
    transform: "scale(0.9)",
    ...customContainerStyle,
  };

  const [containerStyle, setContainerStyle] = useState<StackProps>(
    defaultContainerStyle
  );
  const [toastPhase, setToastPhase] = useState(0);

  const showToastStyle: StackProps = {
    opacity: 1,
    transform: "scale(1)",
  };

  const renderStyleByStatus = (
    status: ToastConfigStatus
  ): RenderStyleByStatusReturnValue => {
    switch (status) {
      case "success":
        return {
          icon: <BsCheckCircleFill size="20px" color="white" />,
          style: {
            bg: "#038767",
          },
        };
      case "info":
        return {
          icon: <BsInfoCircleFill size="20px" color="white" />,
          style: {
            bg: "#044AC4",
          },
        };
      case "warning":
        return {
          icon: <BsExclamationCircleFill size="20px" color="white" />,
          style: {
            bg: "#E6C302",
          },
        };
      case "error":
        return {
          icon: <BsXCircleFill size="20px" color="white" />,
          style: {
            bg: "#D44140",
          },
        };
      case "loading":
        return {
          icon: <Spinner data-testid="spinner" boxSize="20px" color="white" />,
          style: { bg: "#a5a5a5" },
        };
      default:
        return {
          icon: <BsQuestionCircleFill size="20px" color="white" />,
          style: {
            bg: "#a5a5a5",
          },
        };
    }
  };
  const { icon, style: statusStyle } = renderStyleByStatus(status);

  useEffect(() => {
    switch (toastPhase) {
      case 0: {
        const openToast = setTimeout(() => {
          setContainerStyle((prev) => ({ ...prev, ...showToastStyle }));
          setToastPhase((prev) => prev + 1);
        }, 300);

        return () => clearTimeout(openToast);
      }
      case 1: {
        const closeToast = setTimeout(() => {
          setContainerStyle(defaultContainerStyle);
          setToastPhase((prev) => prev + 1);
        }, duration);

        return () => clearTimeout(closeToast);
      }
      case 2: {
        const removeToastConfig = setTimeout(() => {
          removeToast(id as string);
        }, 600);

        return () => clearTimeout(removeToastConfig);
      }
      default:
    }
  }, [toastPhase]);

  return (
    <VStack {...statusStyle} {...containerStyle}>
      <HStack width="full" alignItems="center" spacing="16px">
        {icon}
        <Text color="white" flex={1} fontSize="medium">
          {title}
        </Text>
      </HStack>
      {description && (
        <HStack width="full" alignItems="center" spacing="16px">
          <Box boxSize="20px" />
          <Text color="white" flex={1} fontSize="small">
            {description}
          </Text>
        </HStack>
      )}
    </VStack>
  );
}
