import { useState } from "react";
import { dataProvider } from "src/libs";
import {
  BaseRecords,
  CreateResponse,
  ErrorResponse,
} from "src/libs/dataProvider/types";

import {
  IUseCreateMutateProps,
  IUseCreateProps,
  IUseCreateReturnValue,
} from "./type";

export function useCreate<
  TData extends BaseRecords = BaseRecords,
  TVariables = object,
>({ apiUrl }: IUseCreateProps): IUseCreateReturnValue<TData, TVariables> {
  const { create } = dataProvider(apiUrl);
  const [data, setData] = useState<CreateResponse<TData> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const mutate = async ({
    resource,
    meta,
    variables,
    onSuccess,
    onError,
  }: IUseCreateMutateProps<TData, TVariables>) => {
    setIsLoading(true);
    const postData = await create<TData, TVariables>({
      resource,
      meta,
      variables,
    });

    const fetchError = Boolean((postData.data as ErrorResponse).error);
    setIsSuccess(() => (fetchError ? false : true));
    setIsError(() => (fetchError ? true : false));
    setData(postData);

    if (fetchError) {
      onError?.(postData.data as ErrorResponse, variables);
    }
    if (!fetchError) {
      onSuccess?.(postData.data as TData, variables);
    }
    setIsLoading(false);
  };

  return {
    data,
    mutate,
    isSuccess,
    isError,
    isLoading,
  };
}
