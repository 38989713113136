import { useState } from "react";
import { dataProvider } from "src/libs";
import {
  BaseRecords,
  ErrorResponse,
  UpdateResponse,
} from "src/libs/dataProvider/types";

import {
  IUseUpdateMutateProps,
  IUseUpdateProps,
  IUseUpdateReturnValue,
} from "./type";

export function useUpdate<
  TData extends BaseRecords = BaseRecords,
  TVariables = object,
>({ apiUrl }: IUseUpdateProps): IUseUpdateReturnValue<TData, TVariables> {
  const { update } = dataProvider(apiUrl);
  const [data, setData] = useState<UpdateResponse<TData> | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);
  const [isError, setIsError] = useState(false);

  const mutate = async ({
    resource,
    id,
    variables,
    meta,
    onSuccess,
    onError,
  }: IUseUpdateMutateProps<TData, TVariables>) => {
    setIsLoading(true);
    const updateData = await update<TData, TVariables>({
      id,
      resource,
      meta,
      variables,
    });

    const fetchError = Boolean((updateData.data as ErrorResponse).error);
    setIsSuccess(() => (fetchError ? false : true));
    setIsError(() => (fetchError ? true : false));
    setData(updateData);

    if (fetchError) {
      onError?.(updateData.data as ErrorResponse, variables);
    }
    if (!fetchError) {
      onSuccess?.(updateData.data as TData, variables);
    }
    setIsLoading(false);
  };

  return {
    data,
    mutate,
    isSuccess,
    isError,
    isLoading,
  };
}
