import { useContext, useEffect } from "react";
import { ToastContext } from "src/context";
import { ToastOptions } from "src/context/ToastContext/type";

export function useToast(options: ToastOptions = {}) {
  const { toast, setToastOptions } = useContext(ToastContext);

  useEffect(() => {
    setToastOptions?.(options);
  }, []);

  return toast;
}
